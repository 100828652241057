<template>
  <v-row justify="center">
    <v-dialog
      v-if="resolutionScreen >= 500"
      v-model="shower"
      scrollable
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title
          >{{ $t("fileduplicate.headerfileduplicate") }}<v-spacer></v-spacer>
          <v-icon :color="color.theme" dense dark size="35"
            >mdi-progress-upload</v-icon
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text
          style="height: 400px;"
          class="pa-0 mt-1"
          v-if="listfileduplicate.length > 0"
        >
          <div>
            <v-list v-for="(item, i) in listfileduplicate" :key="i">
              <v-list-item>
                <v-list-item-action>
                  <v-checkbox
                    v-model="selectedduplicate"
                    :value="item"
                    class="pl-1"
                  >
                  </v-checkbox>
                </v-list-item-action>
                <v-list-item-action>
                  <v-icon
                    class=""
                    :color="listfileduplicate[i].icon[1]"
                    large
                    >{{ listfileduplicate[i].icon[0] }}</v-icon
                  >
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="pl-4">
                    {{ listfileduplicate[i].name }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-checkbox
            class="pt-5 mt-n2 mb-n2"
            v-model="selectedAll"
            :label="$t('fileduplicate.selectall')"
          />
          <v-spacer></v-spacer>
          <v-btn
            :disabled="
              selectedduplicate.filter((item) => item.type === 'folder').length !==
                0 || selectedduplicate.length === 0
            "
            v-if="uploadfolder !== 'true'"
            outlined
            @click="
              $emit('replace', selectedduplicate, 'create_tag_version'),
                (selectedduplicate = [])
            "
            color="primary"
          >
            <span>{{ $t("fileduplicate.filereplace") }}</span>
          </v-btn>
          <v-btn
            :disabled="selectedduplicate.length === 0"
            outlined
            @click="
              $emit('copy', selectedduplicate, 'create_copy'),
                (selectedduplicate = [])
            "
            color="success"
          >
            <span>{{ $t("fileduplicate.filecopy") }}</span>
          </v-btn>
          <v-btn
            :disabled="selectedduplicate.length === 0"
            outlined
            @click="
              $emit('skip', selectedduplicate, ''),
                (selectedduplicate = [])
            "
            color="error"
          >
            <span>{{ $t("fileduplicate.fileskip") }}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- หน้าจอมือถือ -->
    <v-dialog
      v-else
      v-model="shower"
      scrollable
      persistent
      :max-width="maxWidthOnMobile"
      content-class="rounded-lg"
    >
      <v-card>
        <v-card-text class="pa-5">
          <v-layout class="pt-2 pb-n1">
            <v-flex d-flex justify-center class="">
              <span :style="headerPage">{{
                $t("fileduplicate.headerfileduplicate")
              }}</span>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-text
          style="height: 400px;"
          class="pa-0 mt-1"
          v-if="listfileduplicate.length > 0"
        >
          <div>
            <v-list v-for="(item, i) in listfileduplicate" :key="i">
              <v-list-item class="mt-n4">
                <v-list-item-action>
                  <v-icon
                    class="pl-2"
                    :color="listfileduplicate[i].icon[1]"
                    large
                    >{{ listfileduplicate[i].icon[0] }}</v-icon
                  >
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ listfileduplicate[i].name }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
        </v-card-text>
        <div class="text-center my-4">
          <v-btn
            :disabled="
              selectedduplicate.filter((item) => item.type === 'folder').length !==
                0 || selectedduplicate.length === 0
            "
            v-if="uploadfolder !== 'true'"
            width="28%"
            class="mr-2"
            height="40px"
            :style="btnAction"
            outlined
            @click="
              $emit('replace', selectedduplicate, 'create_tag_version'),
                (selectedduplicate = [])
            "
            color="primary"
          >
            <span>{{ $t("fileduplicate.filereplace") }}</span>
          </v-btn>
          <v-btn
            :disabled="selectedduplicate.length === 0"
            width="29%"
            class="mr-2"
            height="40px"
            :style="btnAction"
            outlined
            @click="
              $emit('copy', selectedduplicate, 'create_copy'),
                (selectedduplicate = [])
            "
            color="success"
          >
            <span>{{ $t("fileduplicate.filecopy") }}</span>
          </v-btn>
          <v-btn
            :disabled="selectedduplicate.length === 0"
            width="28%"
            height="40px"
            :style="btnAction"
            outlined
            @click="
              $emit('skip', selectedduplicate, ''),
                (selectedduplicate = [])
            "
            color="error"
          >
            <span>{{ $t("fileduplicate.fileskip") }}</span>
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import VueCookies from "vue-cookies";
import { mapState, mapGetters } from "vuex";
export default {
  props: ["show", "listfileduplicate", "uploadfolder"],
  data: function() {
    return {
      // checkfirstloopchoice: false,
      moredetail: false,
      selectedduplicate: [],
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    shower: {
      get() {
        if (this.show === true) {
          this.selectedduplicate = [...this.listfileduplicate];
        } else {
          this.selectedduplicate = [];
          // this.checkfirstloopchoice = false;
        }
        return this.show;
      },
    },
    // สำหรับการเลือกทั้งหมด
    selectedAll: {
      set(val) {
        this.selectedduplicate = [];
        if (val) {
          for (let i = 1; i <= this.listfileduplicate.length; i++) {
            this.selectedduplicate.push(this.listfileduplicate[i - 1]);
          }
        }
      },
      get () {
        if (this.show === false) {
          return false;
        } else if (this.selectedduplicate.length === this.listfileduplicate.length) {
          return true;
        }
      },
    },
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    // style อักษร header ของ mobile
    headerPage() {
      return (
        "color:" +
        this.color.theme +
        ";" +
        "font-size: 16px; font-weight: 600; font-weight: bold; letter-spacing: 0px;"
      );
    },
    // style ปุ่ม ของ mobile
    btnAction() {
      return "font-size: 16px; font-weight: lighter;";
    },
    // style อักษรใน chip ของ mobile
    headerPageMobile() {
      return "color:white;" + "font-size: 16px; line-height: 24px;";
    },
    // style chip ของ mobile
    headerChipPage() {
      return "width: 100%; min-height: 40px; max-height: auto; height: auto !important; border-radius: 40px;";
    },
    // ความกว้างของ dialog ของ ipad และ mobile
    maxWidthOnMobile() {
      if (this.resolutionScreen >= 400) {
        return 600;
      } else {
        return 346;
      }
    },
  },
  methods: {},
};
</script>

<style></style>
